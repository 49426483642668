import resolveUrl from "./resolve-url";

const resolveProductUrl = (product) => {
  if (product.specific_url) {
    return resolveUrl(product.specific_url);
  }

  if (product.bank?.specific_url) {
    return resolveUrl(product.bank.specific_url, product.url)
  }

  if (product.bank?.prefix_url) {
    return resolveUrl(product.bank.prefix_url, product.bank.url, product.url)
  }

  return resolveUrl(product.prefix_url, product.url);
}

export default resolveProductUrl;